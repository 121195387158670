import React, { useEffect, useContext } from "react";
import { useMutation } from "react-query";
import "./abmType.scoped.scss"
import CustomModal from "../../components/share/customModal/customModal"
import { ListaDeBotones, ListaDeBotonesModals } from "../../components/share/listaDeBotones/botones";
import InputForm from "../../components/share/inputForm/inputForm";
import ResultSearch from "../../components/share/resultSearch/resultSearch";
import { getTypes, TypeAlta, typeDelete, TypeEdit } from "../../services";
import { useModalWithData } from "../../customHooks/useModalWithData";
import Pagination from "../../components/share/pagination/pagination";
import contextSnackbar from "../../context/contextSnackbar";
import contextToken from "../../context/tokenContext";
import ApiUrlContext from "../../context/ApiUrlContext";
// import Loader from "../../components/Loader/Loader";
import ContextLoading from "../../context/contextLoader";

export default function AbmType(){

    const getContextLoading = useContext(ContextLoading);

    const getContextSnackbar = useContext(contextSnackbar);
    const getContextToken = useContext(contextToken);
    const getApiUrlContext = useContext(ApiUrlContext);

    const [ setIsModalOpenedDelete, isModalOpenedDelete, modalDataDelete, setModalDataDelete ] = useModalWithData();
    const [ setIsModalOpenedPost, isModalOpenedPost ] = useModalWithData();
    const [ setIsModalOpenedUpdate, isModalOpenedUpdate, modalDataUpdate, setModalDataUpdate ] = useModalWithData();

    const [totalRowPage, setTotalRowPage] = React.useState(0);
    const [newType, setNewType] = React.useState("");
    const [searchType, setSearchType] = React.useState("");
    const [CurrentListtype, setCurrentListType] = React.useState([]);
    const [CurrentListtypeInitial, setCurrentListTypeInitial] = React.useState([]);
    const [pagination, setPagination] = React.useState(1);
    
    const mutationGetTypes = useMutation((values) => getTypes(values, getContextToken, getApiUrlContext));
    const mutationTypeAlta = useMutation((values) => TypeAlta(values, getContextToken, getApiUrlContext));
    const mutationTypeEdit = useMutation((values) => TypeEdit(values, getContextToken, getApiUrlContext));
    const mutationTypeDelete = useMutation((values) => typeDelete(values, getContextToken, getApiUrlContext));

    useEffect( () => {
        getContextLoading.setLoadingState(true);
        mutationGetTypes.mutate(
            {
                keySearch: searchType,
                pagination
            }
        );
    }, [pagination])
    
    useEffect( () => {
        if(mutationGetTypes.isSuccess){
            setCurrentListType(mutationGetTypes.data.Data || [])
            setCurrentListTypeInitial(JSON.parse(JSON.stringify(mutationGetTypes.data.Data)) || [])
            // setCurrentListType(mutation)
            setTotalRowPage(mutationGetTypes.data.Quantity)
            getContextLoading.setLoadingState(false);
        }
    }, [mutationGetTypes.isSuccess])

    useEffect( () => {
        if(mutationTypeAlta.isSuccess){
            setIsModalOpenedPost(false);
            mutationGetTypes.mutate(
                {
                    keySearch: searchType,
                    pagination
                }
            );
            getContextLoading.setLoadingState(false);
            getContextSnackbar.setSnackbar({
                type: "success",
                msj: "Registro creado correctamente",
              })
        }
    }, [mutationTypeAlta.isSuccess])

    useEffect( () => {
        if(mutationTypeAlta.isError){
            setIsModalOpenedPost(false);
            getContextLoading.setLoadingState(false);
            getContextSnackbar.setSnackbar({
                type: "error",
                msj: `${mutationTypeAlta.error.response.data.Message || 'Ocurrio un error al intentar agregar'}`,
              })
        }
    }, [mutationTypeAlta.isError])

    useEffect( () => {
        if(mutationTypeEdit.isSuccess){
            setIsModalOpenedUpdate(false);
            mutationGetTypes.mutate(
                {
                    keySearch: searchType,
                    pagination
                }
            );
            getContextLoading.setLoadingState(false);
            getContextSnackbar.setSnackbar({
                type: "success",
                msj: "Registro actualizado correctamente",
              })
        }
    }, [mutationTypeEdit.isSuccess])

    useEffect( () => {
        if(mutationTypeEdit.isError){
            setIsModalOpenedUpdate(false);
            getContextLoading.setLoadingState(false);
            getContextSnackbar.setSnackbar({
                type: "error",
                msj: `${mutationTypeEdit.error.response.data.Message || 'Ocurrio un error al intentar editar'}`,
              })
        }
    }, [mutationTypeEdit.isError])
    
    useEffect( () => {
        if(mutationTypeDelete.isSuccess){
            setIsModalOpenedDelete(false);
            mutationGetTypes.mutate(
                {
                    keySearch: searchType,
                    pagination
                }
            );
            getContextLoading.setLoadingState(false);
            getContextSnackbar.setSnackbar({
                type: "success",
                msj: "Registro eliminado correctamente",
              })
        }
    }, [mutationTypeDelete.isSuccess])

    useEffect( () => {
        if(mutationTypeDelete.isError){
            setIsModalOpenedDelete(false);
            getContextLoading.setLoadingState(false);
            getContextSnackbar.setSnackbar({
                type: "error",
                msj: `${mutationTypeDelete.error.response.data.Message || 'Ocurrio un error al intentar eliminar'}`,
              })
        }
    }, [mutationTypeDelete.isError])


    useEffect( () => {
        mutationGetTypes.mutate(
            {
                keySearch: searchType,
                pagination
            }
        );
    }, [searchType] )
    
    function handleSubmit() {
        setIsModalOpenedPost(true)
    }

    function searchTypes() {
        mutationGetTypes.mutate({
            keySearch: searchType,
            pagination
        });
    }

    function deleteType(idRegister) {
        setModalDataDelete(idRegister)
        setIsModalOpenedDelete(true)
    }

    function editRegister(idRegister) {
        const result = CurrentListtype.filter(type => type.Id === idRegister);

        setNewType(result[0].Name);
        setModalDataUpdate(idRegister)

        setIsModalOpenedUpdate(true);
    }

    function AddRegisterModal(state, mutation, data) {
        if(state === "") {
            getContextSnackbar.setSnackbar({
                type: "error",
                msj: "Error : Campos vacios en el formulario",
              })
        }else{
            getContextLoading.setLoadingState(true);
            mutation.mutate(data);
        }
    }

    function editRegisterModal(state, mutation, data) {
        if(state === "") {
            getContextSnackbar.setSnackbar({
                type: "error",
                msj: "Error : Campos vacios en el formulario",
            })
        }else{
            getContextLoading.setLoadingState(true);
            mutation.mutate(data);
        }
    }

    return (
        <>
            {/* //////////////////////////////INICIO MODALES////////////////////////// */}

            <CustomModal
                isActive={isModalOpenedDelete}
                header="Eliminar Tipo"
                title="¿Esta seguro que desea eliminar este tipo?"
                showWarning={true}
                handleClose={() => {setIsModalOpenedDelete(false)}}
            >
                <ListaDeBotonesModals handlerSubmit={() => {
                    getContextLoading.setLoadingState(true);
                    mutationTypeDelete.mutate(modalDataDelete)}
                    } handleCancel={() => {setIsModalOpenedDelete(false)}}/>
            </CustomModal>
            <CustomModal
                isActive={isModalOpenedPost}
                header="Agregar Tipo"
                title="Ingrese el nombre del tipo que desea generar"
                handleClose={() => {setIsModalOpenedPost(false)}}
            >                
                <div className="inputModalContainer">
                    <div className="inputModal">
                        <label>Ingrese un nuevo tipo de contacto</label>
                        <input placeholder="Ingrese un nuevo tipo" onChange={ (e) => {setNewType(e.target.value)}}/>
                    </div>
                </div>
                <ListaDeBotonesModals handlerSubmit={() => {AddRegisterModal( newType, mutationTypeAlta, {Name: newType} )}} handleCancel={() => {setIsModalOpenedPost(false)}}/>
                {/* <ListaDeBotonesModals handlerSubmit={() => {mutationTypeAlta.mutate({Name: newType})}} handleCancel={() => {setIsModalOpenedPost(false)}}/> */}
            </CustomModal>
            <CustomModal
                isActive={isModalOpenedUpdate}
                header="Editar Tipo"
                title="Ingrese el nombre del tipo que desea cambiar"
                handleClose={() => {setIsModalOpenedUpdate(false)}}
            >                
                <div className="inputModalContainer">
                    <div className="inputModal">
                        <label>Ingrese un nuevo tipo de contacto</label>
                        <input value={newType} placeholder="Ingrese un nuevo tipo" onChange={ (e) => {setNewType(e.target.value)}}/>
                    </div>
                </div>
                    <ListaDeBotonesModals 
                    handlerSubmit={() => {
                        editRegisterModal(
                            newType, 
                            mutationTypeEdit, 
                            {
                                id : modalDataUpdate,
                                value : {Name: newType}
                            })
                        }} 
                        handleCancel={() => {setIsModalOpenedUpdate(false)}}/>
                {/* <ListaDeBotonesModals handlerSubmit={() => {editRegisterModal()}} handleCancel={() => {setIsModalOpenedUpdate(false)}}/> */}

            </CustomModal>

            {/* //////////////////////////////FIN MODALES////////////////////////// */}
            <form className="containerForm" onSubmit={handleSubmit}>
                {/* <div className={css.titleForm}>
                    <h1>
                        TIPO DE CONTACTO
                    </h1>
                </div> */}
                <div className="card card--white">
                    <div className="headerForm">
                        <InputForm type="input" label="ABM Tipos" idList="type" setState={setSearchType} 
                                    searchTypes={searchTypes} listOptionsInitial={CurrentListtypeInitial} 
                                    setCurrentListType={setCurrentListType} setPagination={setPagination}/>
                        <ListaDeBotones
                                handlerSubmit={() => handleSubmit()}
                                handlerSubmitDelete={() => deleteType()}
                        />
                    </div>
                </div>
                <div className="card card--white">
                    <ResultSearch 
                        arrayToIterate={CurrentListtype}
                        editRegister={editRegister}
                        deleteRegister={deleteType}
                        showColumnsName={["Name"]}
                        columnNameTranslate={{
                            Name: "Tipo de contacto"
                        }}
                    />
                    {
                        ((CurrentListtype.length > 0) || (CurrentListtype.length == 0 && searchType.length == 0)) &&
                        <div className="contFooter">
                            <Pagination totalRow={totalRowPage} pagination={pagination} setPagination={setPagination}/>
                        </div>
                    }
                </div>
            </form>
            {/* {
                loadingProcess &&
                <Loader />
            } */}
        </>
    );
}