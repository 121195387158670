import React from "react";
import "./customModal.scoped.scss"

export default function ModalContents({ isActive, children, header, title, handleClose, showWarning=false, showCloseButton = false}) {

  if(isActive === true) {
    return (
      <>
          <div className="modal__overlay" onClick={handleClose}>
          <div className="modal" onClick={event => event.stopPropagation()}>
            {
              showCloseButton &&
              <span className="material-icons" onClick={handleClose}>close</span>
            }
            <h3>{header}</h3>
            {children}
          </div>
        </div>
      {/* // <div className={css.overlay} onClick={handleClose}></div>
      //   <Modal style={{position: 'fixed',width: '40%' ,top: '50%', left: '50%', border: '0.5px solid #ccc', transform: 'translate( -50%, -50% )', borderRadius: '12px', boxShadow: '0px 4px 30px rgba(0, 0, 0, 0.25)'}} isActive={isActive}>
      //     <ModalBackground>
      //     <ModalContent className={css.modalContent} style={{backgroundColor:'white', padding: '1rem', maxWidth: '100vw', borderRadius: '12px', boxShadow: '0px 4px 30px rgba(0, 0, 0, 0.25)'}}>
      //       <div className={css.headerModal}>
      //         <span><strong>{header}</strong></span>
      //       </div>
      //       <hr />
      //       <Title style={{fontSize: '15px'}}>
      //         {title}
      //         <br />
      //         {showWarning && <strong>Esta accion no se puede deshacer</strong>}
      //       </Title>
      //       {children}
      //     </ModalContent>
      //     </ModalBackground>
      //   </Modal> */}
      </>
    )}
  else{
    return null;
  }
}