import { useState, useEffect } from "react";
import { useMutation } from "react-query";
import { BrowserRouter, Routes, Route } from "react-router-dom"
import './App.scss';
import './styles/theme-styles.scss';
import Spinner from "./assets/icons/spiner.svg"
import AbmType from "./pages/abmType"
import AbmSubject from "./pages/abmSubject"
import AbmReason from "./pages/abmReason"
import contextSnackbar from "./context/contextSnackbar";
import TokenContext from "./context/tokenContext"
import ApiUrlContext from "./context/ApiUrlContext"
import ContextLoading from "./context/contextLoader";
import { getUserData } from "./services"

const styleDivLoading = {
  position: "fixed",
  width: "100vw",
  height: "100vh",
  background: "rgba(93, 92, 92, 0.568)",
  zIndex: 999,
};

const styleH1Loading = {
  position: "absolute",
  left: "45%",
  top: "40%",
  zIndex: "999",
  width: "150px",
  height: "150px",
};

function App() {

  const [isLoading, setLoadingState] = useState(false);

  const mutatioGetUserData = useMutation((url) => getUserData(url));

  const [token, setToken] = useState('');
  const [APIURL, SETAPIURL] = useState({});

  const loadingObject = {
    isLoading,
    setLoadingState,
  };

  useEffect( () => {
    fetch('./config.json')
    .then(response => response.json())
    .then(data => {
      SETAPIURL(data.APP_ICM)
      mutatioGetUserData.mutate(data.APP_BK)
    }
    );
  }, [])

  useEffect( () => {
    if(mutatioGetUserData.isSuccess) {
      setToken( mutatioGetUserData.data.data.token);
    }
  }, [mutatioGetUserData])

  const [Snackbar, setSnackbar] = useState("");

  const SnackbarObject = {
    Snackbar,
    setSnackbar,
  };

  useEffect(() => {
    setTimeout(() => {
      setSnackbar("");
    }, 3000);
  }, [Snackbar]);

  if(!token || !APIURL) {
    return (
      <div style={styleDivLoading}>
        <img
          alt="imgLoading"
          style={styleH1Loading}
          src={Spinner}
        />
      </div>
    )
  }
  return (
    <div className="App">
      <header className="App-header">
      <ContextLoading.Provider value={loadingObject}>
        <TokenContext.Provider value={token}>
          <ApiUrlContext.Provider value={APIURL}>
            <contextSnackbar.Provider value={SnackbarObject}>

            {Snackbar && (
              <div
                className={Snackbar.type === "success" ? "snackbar snackbar--success": "snackbar snackbar--error"}
              >
                <span>{Snackbar.msj}</span>
              </div>
              )}
              <BrowserRouter>
                {isLoading && (
                  <div style={styleDivLoading}>
                    <img
                      alt="imgLoading"
                      style={styleH1Loading}
                      src={Spinner}
                    />
                  </div>
                )}
                <Routes>          
                    <Route path="/" element={ 
                      <>
                        <a href="/abmType">Go to ABM TIPOS</a> 
                        <a href="/abmSubject">Go to ABM ASUNTO</a> 
                        <a href="/abmReason">Go to ABM MOTIVO</a> 
                      </>
                    } />
                    <Route path="abmType" element={ <AbmType /> } />
                    <Route path="abmSubject" element={ <AbmSubject /> } />
                    <Route path="abmReason" element={ <AbmReason /> } />
                </Routes>
              </BrowserRouter>
            </contextSnackbar.Provider>
            </ApiUrlContext.Provider>
          </TokenContext.Provider>
        </ContextLoading.Provider>
      </header>
    </div>
  );
}

export default App;
