import React from "react";
import "./resultSearch.scoped.scss"
import editIcon from "../../../assets/icons/editIco.svg"
import deleteIcon from "../../../assets/icons/deleteIco.svg"

export default function ResultSearch({ arrayToIterate, editRegister, deleteRegister, showColumnsName, columnNameTranslate}) {

  const headers = arrayToIterate.length > 0 ? Object.keys(arrayToIterate[0]) : [];

  return (
    <>
    
    <div className="containerAll">
    {arrayToIterate.length > 0 &&
      <div className="containerResultSearchHeader">
        {headers.map( (header) => {
            return (
              showColumnsName.includes(header) > 0 &&
                <div className="containerResultSearchHeaderColumn" key={header}>
                  {columnNameTranslate[header]}
                </div>        
            )}
          )}
          <div className="containerResultSearchHeaderColumn">
            Opciones
          </div>
      </div>
    }
      
      {arrayToIterate.length > 0 ? arrayToIterate.map( (register, i) =>
        <div key={register.Id} className="containerResultSearch">
          {headers.map( (header) => {
            return(
            showColumnsName.includes(header) > 0 &&
              <div title={register[`${header}`].length > 46 ? register[`${header}`] : null} className="containerResultSearchData" key={header}>
                <span>{register[`${header}`].substr(0, 36)}{register[`${header}`].length > 46 && "..."}</span>
              </div>
            )
          })}
          <div className="containerImg">
            <span className="material-icons" onClick={ () => editRegister(register.Id)} src={editIcon}>edit</span>
            <span className="material-icons" onClick={ () => deleteRegister(register.Id)} src={deleteIcon}>delete</span>
          </div>
        </div>
      ):
        <div className="containerResultSearchWithoutInfo">
          <div className="withoutInformation" >
            <span>No hay informacion para mostrar</span>
          </div>
        </div>
      }
      </div>
    </>
  )
}