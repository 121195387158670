import React, { useEffect, useContext, useState } from "react";
import { useMutation } from "react-query";
import "./abmSubject.scoped.scss"
import CustomModal from "../../components/share/customModal/customModal"
import { ListaDeBotones, ListaDeBotonesModals } from "../../components/share/listaDeBotones/botones";
import InputForm from "../../components/share/inputForm/inputForm";
import ResultSearch from "../../components/share/resultSearch/resultSearch";
import { getTypesAll, getSubject, altaSubject, subjectDelete, subjectEdit } from "../../services";
import { useModalWithData } from "../../customHooks/useModalWithData";
import Pagination from "../../components/share/pagination/pagination";
import contextSnackbar from "../../context/contextSnackbar";
import contextToken from "../../context/tokenContext";
import ApiUrlContext from "../../context/ApiUrlContext";
import ContextLoading from "../../context/contextLoader";

export default function AbmSubject(){
    
    const getContextLoading = useContext(ContextLoading);

    const getContextSnackbar = useContext(contextSnackbar);
    const getContextToken = useContext(contextToken);
    const getApiUrlContext = useContext(ApiUrlContext);

    const [ setIsModalOpenedDelete, isModalOpenedDelete, modalDataDelete, setModalDataDelete ] = useModalWithData();
    const [ setIsModalOpenedPost, isModalOpenedPost ] = useModalWithData();
    const [ setIsModalOpenedUpdate, isModalOpenedUpdate, modalDataUpdate, setModalDataUpdate ] = useModalWithData();

    
    const [searchSubject, setSearchSubject] = React.useState("");
    const [totalRowPage, setTotalRowPage] = React.useState(0);
    
    const [CurrentListtype, setCurrentListType] = React.useState([]);
    const [subject, setSubject] = useState([]);
    const [subjectInitial, setSubjectInitial] = useState([]);
    const [pagination, setPagination] = React.useState(1);

    const mutationGetTypesAll = useMutation((values) => getTypesAll(getContextToken, getApiUrlContext));
    const mutationGetSubject = useMutation((value) => getSubject(value, getContextToken, getApiUrlContext));
    const mutationAddSubject = useMutation((values) => altaSubject(values, getContextToken, getApiUrlContext));
    const mutationEditSubject = useMutation((values) => subjectEdit(values, getContextToken, getApiUrlContext));
    const mutationDeleteSubject = useMutation((values) => subjectDelete(values, getContextToken, getApiUrlContext));
    
    const [newType, setNewType] = React.useState("");
    const [newSubject, setNewSubject] = React.useState("");

    useEffect( () => {
        getContextLoading.setLoadingState(true);
        mutationGetTypesAll.mutate();
        mutationGetSubject.mutate(
            {
                keySearch: searchSubject,
                pagination
            }
        );
    }, [pagination])

    useEffect( () => {
        if(mutationGetTypesAll.isSuccess){
            setNewType(mutationGetTypesAll.data && mutationGetTypesAll.data.Data && mutationGetTypesAll.data.Data.length > 0 ? mutationGetTypesAll.data.Data[0].Id : [])

            setCurrentListType(mutationGetTypesAll.data ? mutationGetTypesAll.data.Data : [])
        
            getContextLoading.setLoadingState(false);
        }
    }, [mutationGetTypesAll.isSuccess])
    useEffect( () => {
        if(mutationGetSubject.isSuccess){
            setSubject(mutationGetSubject.data ? mutationGetSubject.data.Data : [])
            setSubjectInitial(mutationGetSubject.data ? JSON.parse(JSON.stringify(mutationGetSubject.data.Data)) : [])
            setTotalRowPage(mutationGetSubject.data.Quantity)
        }
    }, [mutationGetSubject.isSuccess])

    useEffect( () => {
        if(mutationAddSubject.isSuccess){
            setIsModalOpenedPost(false);
            mutationGetSubject.mutate(
                {
                    keySearch: searchSubject,
                    pagination
                }
            );
            getContextLoading.setLoadingState(false);
            getContextSnackbar.setSnackbar({
                type: "success",
                msj: "Registro creado correctamente",
              })
        }
    }, [mutationAddSubject.isSuccess])

    useEffect( () => {
        if(mutationAddSubject.isError){
            getContextLoading.setLoadingState(false);
            getContextSnackbar.setSnackbar({
                type: "error",
                msj: `${mutationAddSubject.error.response.data.Message || 'Ocurrio un error al intentar editar'}`,
              })
        }
    }, [mutationAddSubject.isError])


    useEffect( () => {
        if(mutationEditSubject.isSuccess){
            setIsModalOpenedUpdate(false);
            mutationGetSubject.mutate(
                {
                    keySearch: searchSubject,
                    pagination
                }
            );
            getContextLoading.setLoadingState(false);
            getContextSnackbar.setSnackbar({
                type: "success",
                msj: "Registro actualizado correctamente",
              })
        }
    }, [mutationEditSubject.isSuccess])

    useEffect( () => {
        if(mutationEditSubject.isError){
            getContextLoading.setLoadingState(false);
            getContextSnackbar.setSnackbar({
                type: "error",
                msj: `${mutationEditSubject.error.response.data.Message || 'Ocurrio un error al intentar editar'}`,
              })
        }
    }, [mutationEditSubject.isError])

    useEffect( () => {
        if(mutationDeleteSubject.isSuccess){
            console.log(mutationDeleteSubject)
            setIsModalOpenedDelete(false);
            mutationGetSubject.mutate(
                {
                    keySearch: searchSubject,
                    pagination
                }
            );
            getContextLoading.setLoadingState(false);
            getContextSnackbar.setSnackbar({
                type: "success",
                msj: "Registro eliminado correctamente",
              })
        }
    }, [mutationDeleteSubject.isSuccess])

    useEffect( () => {
        if(mutationDeleteSubject.isError){
            getContextLoading.setLoadingState(false);
            getContextSnackbar.setSnackbar({
                type: "error",
                msj: `${mutationDeleteSubject.error.response.data.Message || 'Ocurrio un error al intentar eliminar'}`,
              })
        }
    }, [mutationDeleteSubject.isError])

    useEffect( () => {
        mutationGetSubject.mutate(
            {
                keySearch: searchSubject,
                pagination
            }
        );
    }, [searchSubject] );

    function handleSubmit() {
        setNewSubject("");
        setNewType("")
        setIsModalOpenedPost(true)
    }

    function search() {
        mutationGetSubject.mutate({
            keySearch: searchSubject,
            pagination
        });
    }

    function deleteType(idRegister) {
        setModalDataDelete(idRegister)
        setIsModalOpenedDelete(true)
    }

    function editRegister(idRegister) {
        const result = subject.filter(subj => subj.Id === idRegister);

        setNewSubject(result[0].Name);
        setNewType(result[0].TypeId);
        setModalDataUpdate(idRegister);

        setIsModalOpenedUpdate(true);
    }

    function AddRegisterModal(state, mutation, data) {
        if(state === "" || newType === "") {
            getContextSnackbar.setSnackbar({
                type: "error",
                msj: "Error : Campos vacios en el formulario",
              })        }
        else{            
            getContextLoading.setLoadingState(true);
            mutation.mutate(data);
        }
    }

    function editRegisterModal(state, mutation, data) {
        if(state === "" || newType === "") {
            getContextSnackbar.setSnackbar({
                type: "error",
                msj: "Error : Campos vacios en el formulario",
              })        }
        else{
            getContextLoading.setLoadingState(true);
            mutation.mutate(data);
        }
    }

    return (
        <>
        {/* //////////////////////////////INICIO MODALES////////////////////////// */}
            <CustomModal
                isActive={isModalOpenedDelete}
                header="Eliminar Asunto"
                title="¿Esta seguro que desea eliminar este asunto?"
                showWarning={true}
                handleClose={() => {setIsModalOpenedDelete(false)}}
            >
                <ListaDeBotonesModals handlerSubmit={() => {
                    getContextLoading.setLoadingState(true);
                    mutationDeleteSubject.mutate(modalDataDelete)
                    }} 
                    handleCancel={() => {setIsModalOpenedDelete(false)}}/>
            </CustomModal>
            <CustomModal
                isActive={isModalOpenedPost}
                header="Agregar Asunto"
                title="Ingrese el nombre del asunto que desea generar"
                handleClose={() => {setIsModalOpenedPost(false)}}
            >         
                <div className="inputModalContainer">
                <div className="inputModal">
                    <label>Ingrese un tipo de contacto a asociar</label>
                    {/* <label>Seleccione el tipo a vincular</label> */}
                    <select onChange={ (e) => {setNewType(e.target.value)}}>
                        <option disabled={true} selected value="nuevo asunto">Seleccione un nuevo tipo</option>
                        {CurrentListtype && CurrentListtype.map( (type) => {
                            return (
                                <option key={type.Id} value={type.Id}>{type.Name}</option>
                            )
                        })}
                    </select>
                    </div>
                    <div className="inputModal">
                        <label>Ingrese un nuevo asunto de contacto</label>
                        <input placeholder="Ingrese un nuevo asunto" onChange={ (e) => {setNewSubject(e.target.value)}}/>
                    </div>
                </div>
                <ListaDeBotonesModals handlerSubmit={() => {AddRegisterModal( newSubject, mutationAddSubject, {Name: newSubject, TypeId : newType} )}} handleCancel={() => {setIsModalOpenedPost(false)}}/>
            </CustomModal>            
            <CustomModal
                isActive={isModalOpenedUpdate}
                header="Editar Asunto"
                title="Ingrese el nombre del asunto que desea cambiar"
                handleClose={() => {setIsModalOpenedUpdate(false)}}
            >                
                <div className="inputModalContainer">
                <div className="inputModal">
                <label>Ingrese un tipo de contacto a asociar</label>
                    <select onChange={ (e) => {setNewType(e.target.value)}}>
                        {CurrentListtype && CurrentListtype.map( (type) => {
                            return (
                                newType === type.Id ?
                                <option selected key={type.Id} value={type.Id}>{type.Name}</option>
                               :
                               <option key={type.Id} value={type.Id}>{type.Name}</option>
                            )
                        })}
                    </select>
                </div>
                <div className="inputModal">
                <label>Ingrese un nuevo asunto de contacto</label>
                    <input value={newSubject} onChange={ (e) => {setNewSubject(e.target.value)}}/>
                </div>
                </div>
                <ListaDeBotonesModals 
                    handlerSubmit={() => {
                        editRegisterModal(
                            newSubject, 
                            mutationEditSubject, 
                            {
                                id : modalDataUpdate,
                                value : {Name: newSubject, TypeId : newType}
                            })
                        }} 
                    handleCancel={() => {setIsModalOpenedUpdate(false)}}/>
            </CustomModal>

            {/* //////////////////////////////FIN MODALES////////////////////////// */}
            <form className="containerForm" onSubmit={handleSubmit}>
                {/* <div className="titleForm">
                    <h1>
                        ASUNTO DE CONTACTO
                    </h1>
                </div> */}
                <div className="card card--white">
                    <div className="headerForm">
                        <InputForm type="input" label="ABM Subject" idList="sbuject" setState={setSearchSubject} 
                                    searchTypes={search} listOptions={subject} listOptionsInitial={subjectInitial} 
                                    setCurrentListType={setSubject} setPagination={setPagination}/>
                        <ListaDeBotones
                            handlerSubmit={() => handleSubmit()}
                            handlerSubmitDelete={() => deleteType()}
                        />
                    </div>
                </div>
                <div className="card card--white">
                    <ResultSearch 
                        arrayToIterate={subject}
                        editRegister={editRegister}
                        deleteRegister={deleteType}                    
                        showColumnsName={["Name", "TypeName"]}
                        columnNameTranslate={{
                            Name: "Asunto de contacto",
                            TypeName: "Tipo de contacto"
                        }}
                    />
                    {
                        ((subject.length > 0) || (subject.length == 0 && searchSubject.length == 0)) &&
                        <div className="contFooter">
                            <Pagination totalRow={totalRowPage} pagination={pagination} setPagination={setPagination}/>
                        </div>
                    }
                </div>
            </form>
        </>
    );
}