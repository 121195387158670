import css from "./styles.module.scss";

export function DefaultButton({
  title,
  bgColor = "transparent",
  color = "",
  padding = "0",
  borderRadius = "3px",
  outline = 'none',
  border = 'none',
  add = '',
  type= 'right'
}) {
  return (
    <div className={css.containerBut}>
      {type === "left" ?
        <>
          <img className={css.icon} alt="iconImage" style={{marginRight: "8px"}} src={add} />
          <button
            type="button"
            style={{ backgroundColor: bgColor, color, padding, borderRadius: borderRadius, outline, border }}
          >
            <span>{title}</span>
          </button>
        </>
      :
        <>
          <button
            type="button"
            style={{ backgroundColor: bgColor, color, padding, borderRadius: borderRadius, outline, border, height:"100%" }}
          >
            <span>{title}</span>
          </button>
          {add && <img className={css.icon} alt="iconImage" style={{marginLeft: "8px"}} src={add} />}
        </>
      }
    </div>
  );
}
