import React, { useEffect, useContext, useState } from "react";
import { useMutation } from "react-query";
import "./abmReason.scoped.scss"
import CustomModal from "../../components/share/customModal/customModal"
import { ListaDeBotones, ListaDeBotonesModals } from "../../components/share/listaDeBotones/botones";
import InputForm from "../../components/share/inputForm/inputForm";
import ResultSearch from "../../components/share/resultSearch/resultSearch";
import { getSubjectAll, getTypesAll, getReason, addReason, deleteReason, ReasonEdit, getDepartmentAll } from "../../services";
import { useModalWithData } from "../../customHooks/useModalWithData";
import Pagination from "../../components/share/pagination/pagination";
import contextSnackbar from "../../context/contextSnackbar";
import contextToken from "../../context/tokenContext";
import ApiUrlContext from "../../context/ApiUrlContext";
import ContextLoading from "../../context/contextLoader";

export default function AbmReason(){
    

    const getContextLoading = useContext(ContextLoading);
    
    const getContextSnackbar = useContext(contextSnackbar);
    const getContextToken = useContext(contextToken);
    const getApiUrlContext = useContext(ApiUrlContext);

    const [ setIsModalOpenedDelete, isModalOpenedDelete, modalDataDelete, setModalDataDelete ] = useModalWithData();
    const [ setIsModalOpenedPost, isModalOpenedPost ] = useModalWithData();
    const [ setIsModalOpenedUpdate, isModalOpenedUpdate, modalDataUpdate, setModalDataUpdate ] = useModalWithData();
    
    const [searchReason, setSearchReason] = React.useState("");
    
    const [CurrentListType, setCurrentListType] = React.useState([]);
    const [CurrentListSubject, setCurrentListSubject] = React.useState([]);
    const [CurrentListDepartment, setCurrentListDepartment] = React.useState([]);
    const [Reason, setReason] = useState([]);
    const [ReasonInitial, setReasonInitial] = useState([]);
    const [pagination, setPagination] = React.useState(1);

    // const mutationGetResons = useMutation((values) => getSubjects(values));
    const mutationGetTypesAll = useMutation((values) => getTypesAll(getContextToken, getApiUrlContext));
    const mutationGetSubjectsAll = useMutation((filter) => getSubjectAll(getContextToken, getApiUrlContext, filter));
    const mutationGetDepartmentAll = useMutation(() => getDepartmentAll(getContextToken, getApiUrlContext));
    // const mutationGetReasonAll = useMutation(() => getReasonAll());
    const mutationGetReason = useMutation((value) => getReason(value, getContextToken, getApiUrlContext));
    const mutationAddReason = useMutation((values) => addReason(values, getContextToken, getApiUrlContext));
    const mutationEditReason = useMutation((values) => ReasonEdit(values, getContextToken, getApiUrlContext));
    const mutationDeleteReason = useMutation((values) => deleteReason(values, getContextToken, getApiUrlContext));
    
    const [newMailCreate, setNewMailCreate] = React.useState(false);
    const [newMailClose, setNewMailClose] = React.useState(false);
    const [newDepartment, setNewDepartment] = React.useState("");
    const [newDescriptionReason, setNewDescriptionReason] = React.useState("");
    const [newSubject, setNewSubject] = React.useState("");
    const [newType, setNewType] = React.useState("");
    const [newReason, setNewReason] = React.useState("");
    const [totalRowPage, setTotalRowPage] = React.useState(0);

    useEffect( () => {
        getContextLoading.setLoadingState(true);
        mutationGetTypesAll.mutate();
        mutationGetDepartmentAll.mutate();
        mutationGetReason.mutate(
            {
                keySearch: searchReason,
                pagination
            }
        );
    }, [pagination])

    
    useEffect( () => {
        if(mutationGetSubjectsAll.isSuccess){
            setCurrentListSubject(mutationGetSubjectsAll.data ? mutationGetSubjectsAll.data.Data : [])
            getContextLoading.setLoadingState(false);
        }
    }, [mutationGetSubjectsAll.isSuccess])
    useEffect( () => {
        if(mutationGetTypesAll.isSuccess){
            setCurrentListType(mutationGetTypesAll.data.Data)
        }
    }, [mutationGetTypesAll.isSuccess])
    useEffect( () => {
        if(mutationGetDepartmentAll.isSuccess){
            setCurrentListDepartment(mutationGetDepartmentAll.data.Data)
        }
    }, [mutationGetDepartmentAll.isSuccess])
    useEffect( () => {
        if(mutationGetReason.isSuccess){
            setReason(mutationGetReason.data ? mutationGetReason.data.Data : [])
            setReasonInitial(mutationGetReason.data ? JSON.parse(JSON.stringify(mutationGetReason.data.Data)) : [])
            setTotalRowPage(mutationGetReason.data.Quantity)
            getContextLoading.setLoadingState(false);
        }
    }, [mutationGetReason.isSuccess])

    useEffect( () => {
        if(mutationAddReason.isSuccess){
            setIsModalOpenedPost(false);
            mutationGetReason.mutate({
                keySearch: searchReason,
                pagination
            });
            getContextLoading.setLoadingState(false);
            getContextSnackbar.setSnackbar({
                type: "success",
                msj: "Registro creado correctamente",
              })
        }
    }, [mutationAddReason.isSuccess])

    useEffect( () => {
        if(mutationAddReason.isError){
            getContextLoading.setLoadingState(false);
            getContextSnackbar.setSnackbar({
                type: "error",
                msj: `${mutationAddReason.error.response.data.Message || 'Ocurrio un error al intentar agregar'}`,
              })
        }
    }, [mutationAddReason.isError])


    useEffect( () => {
        if(mutationEditReason.isSuccess){
            setIsModalOpenedUpdate(false);
            getContextLoading.setLoadingState(false);
            mutationGetReason.mutate({
                keySearch: searchReason,
                pagination
            });
            getContextLoading.setLoadingState(false);
            getContextSnackbar.setSnackbar({
                type: "success",
                msj: "Registro actualizado correctamente",
              })
        }
    }, [mutationEditReason.isSuccess])

    useEffect( () => {
        if(mutationEditReason.isError){
            getContextLoading.setLoadingState(false);
            getContextSnackbar.setSnackbar({
                type: "error",
                msj: `${mutationEditReason.error.response.data.Message || 'Ocurrio un error al intentar editar'}`,
              })
        }
    }, [mutationEditReason.isError])

    useEffect( () => {
        if(mutationDeleteReason.isSuccess){
            setIsModalOpenedDelete(false);
            mutationGetReason.mutate({
                keySearch: searchReason,
                pagination
            });
            getContextLoading.setLoadingState(false);
            getContextSnackbar.setSnackbar({
                type: "success",
                msj: "Registro eliminado correctamente",
              })
        }
    }, [mutationDeleteReason.isSuccess])

    useEffect( () => {
        if(mutationDeleteReason.isError){
            getContextLoading.setLoadingState(false);
            getContextSnackbar.setSnackbar({
                type: "error",
                msj: `${mutationDeleteReason.error.response.data.Message || 'Ocurrio un error al intentar eliminar'}`,
              })
        }
    }, [mutationDeleteReason.isError])

    useEffect( () => {
        mutationGetReason.mutate(
            {
                keySearch: searchReason,
                pagination
            }
        );
    }, [searchReason] );

    useEffect( () => {
        if(newType !== "") {
            mutationGetSubjectsAll.mutate(newType)
        }
    }, [newType] );

    function handleSubmit() {
        setNewSubject("")
        setNewType("")
        setNewReason("");
        setNewDepartment("");
        setNewDescriptionReason("");
        setNewMailClose(false);
        setNewMailCreate(false);

        setIsModalOpenedPost(true)
    }

    function search() {
        mutationGetReason.mutate({
            keySearch: searchReason,
            pagination
        });
    }

    function deleteType(idRegister) {
        setModalDataDelete(idRegister)
        setIsModalOpenedDelete(true)
    }

    function editRegister(idRegister) {
        const result = Reason.filter(subj => subj.Id === idRegister);

        mutationGetSubjectsAll.mutate(result[0].TypeId)

        setNewSubject(result[0].MatterId);
        setNewType(result[0].TypeId);
        setNewReason(result[0].Name);

        setNewMailClose(result[0].SendMailOnClose);
        setNewMailCreate(result[0].SendMailOnCreate);
        setNewDepartment(result[0].TicketDepartmentId);
        setNewDescriptionReason(result[0].DefaultResponse);
        
        setModalDataUpdate(idRegister)

        setIsModalOpenedUpdate(true);
    }
    
    function AddRegisterModal(mutation, data) {
        if(data.Name === "" || data.MatterId === "") {
            getContextSnackbar.setSnackbar({
                type: "error",
                msj: "Error : Campos vacios en el formulario",
              })        }
        else{
            getContextLoading.setLoadingState(true);
            mutation.mutate(data);
        }
    }

    function editRegisterModal(mutation, data) {
        if(data.value.Name === "" || data.value.MatterId === "") {
            getContextSnackbar.setSnackbar({
                type: "error",
                msj: "Error : Campos vacios en el formulario",
              })        }
        else{
            getContextLoading.setLoadingState(true);
            mutation.mutate(data);
        }
    }

    return (
        <>
        {/* //////////////////////////////INICIO MODALES////////////////////////// */}
            <CustomModal
                isActive={isModalOpenedDelete}
                header="Eliminar Motivos"
                title="Esta seguro que desea eliminar este motivo?"
                showWarning={true}
                handleClose={() => {setIsModalOpenedDelete(false)}}
            >
                <ListaDeBotonesModals 
                handlerSubmit={() => {
                    getContextLoading.setLoadingState(true)
                    mutationDeleteReason.mutate(modalDataDelete)}} 
                    handleCancel={() => {setIsModalOpenedDelete(false)}}/>
            </CustomModal>
            <CustomModal
                isActive={isModalOpenedPost}
                header="Agregar Motivos"
                title="Ingrese el nombre del motivo que desea generar"
                showWarning={true}
                handleClose={() => {
                    setIsModalOpenedPost(false)
                }}
            >                
                <div className="inputModalContainer">
                <div className="inputModal">
                    <label>Ingrese un tipo de contacto a asociar</label>
                    <select onChange={ (e) => {
                        setNewType(e.target.value)}}>
                        <option disabled={true} selected value="fistOption">Seleccione el tipo que desea asociar</option>
                        {CurrentListType && CurrentListType.map( (type) => {
                            return (
                                <option key={type.Id} value={type.Id}>{type.Name}</option>
                            )
                        })}
                    </select>
                </div>   
                <div className="inputModal">
                    <label>Ingrese un asunto de contacto a asociar</label>
                    <select onChange={ (e) => {
                        setNewSubject(e.target.value)
                        }}>
                        {CurrentListSubject.length !== 0 ?  
                        <>
                        <option disabled={true} selected value="fistOption">Seleccione el asunto que desea asociar</option>
                            {CurrentListSubject && CurrentListSubject.map( (type) => {
                                return (
                                    <option key={type.Id} value={type.Id}>{type.Name}</option>
                                )
                            })}
                        </>
                        : <option selected disabled value={'Sin informacion'}>No hay informacion asociada al tipo seleccionado</option>
                    }
                    </select>
                </div>
                <div className="inputModal">    
                    <label>Ingrese un título para su nuevo motivo de contacto</label>
                    <input className="inputText" placeholder="Ingrese un nuevo motivo" onChange={ (e) => {setNewReason(e.target.value)}}/>
                </div>
                <div className="inputModal">
                    <label>Respuesta estandar</label>
                    <textarea 
                        maxlength="2000"
                        placeholder="respuesta estandar | máximo 2000 letras" onChange={ (e) => {setNewDescriptionReason(e.target.value)}}
                    />
                </div>
                <div className="inputModal">    
                    <label>Seleccione el departamento al cual desea asociar</label>
                    <select onChange={ (e) => {setNewDepartment(e.target.value)}}>
                        <>
                        <option disabled={true} selected value="0">Seleccione el departamento que desea asociar</option>
                            {CurrentListDepartment && CurrentListDepartment.map( (department) => {
                                return (
                                    <option key={department.Id} value={department.Id}>{department.Name}</option>
                                )
                            })}
                        </>
                    </select>
                </div>
                <div className="inputModal">   
                    <div className="inputModalLine">
                        <input className="inputCheckbox" name="Ingrese un nuevo motivo" type="checkbox" onChange={ (e) => {setNewMailCreate(!newMailCreate)}}/>
                        <label>Envía email al crear ticket</label>
                    </div>
                    <div className="inputModalLine">
                        <input className="inputCheckbox" placeholder="Ingrese un nuevo motivo" type="checkbox" onChange={ (e) => {setNewMailClose(!newMailClose)}}/>
                        <label>Envía email al cerrar ticket</label>
                    </div>
                </div>
                </div>
                <ListaDeBotonesModals 
                    handlerSubmit={() => {
                        AddRegisterModal( mutationAddReason, {Name: newReason, MatterId : newSubject, SendMailOnCreate: newMailCreate, SendMailOnClose: newMailClose, DefaultResponse: newDescriptionReason, TicketDepartmentId: newDepartment} )
                    }} 
                    handleCancel={() => {
                        setIsModalOpenedPost(false);
                    }}/>
            </CustomModal>            
            <CustomModal
                isActive={isModalOpenedUpdate}
                header="Editar Motivos"
                title="Ingrese el nombre del motivo que desea cambiar"
                handleClose={() => {
                    setIsModalOpenedUpdate(false);
                }}
            >               
                <div className="inputModalContainer">
                <div className="inputModal">
                    <label>Ingrese un tipo de contacto a asociar</label>
                    <select onChange={ (e) => {                        
                        setNewType(e.target.value)
                        setNewSubject("");
                        }}>
                        {CurrentListType && CurrentListType.map( (type) => {
                            return (
                                newType === type.Id ?
                                <option selected key={type.Id} value={type.Id}>{type.Name}</option>
                               :
                               <option key={type.Id} value={type.Id}>{type.Name}</option>
                            )
                        })}
                    </select>
                </div>
                <div className="inputModal">
                    <label>Ingrese un asunto de contacto a asociar</label>
                    <select onChange={ (e) => {
                        setNewSubject(e.target.value)}
                        }>
                        <option disabled={true} selected value="nuevo motivo">Seleccione el asunto que desea asociar</option>
                        {CurrentListSubject.length !== 0 ? CurrentListSubject && CurrentListSubject.map( (subject) => {
                            return (
                                newSubject === subject.Id ?
                                <option selected key={subject.Id} value={subject.Id}>{subject.Name}</option>
                               :
                               <option key={subject.Id} value={subject.Id}>{subject.Name}</option>
                            )
                        })
                        : <option selected disabled value={'Sin informacion'}>No hay informacion asociada al tipo seleccionado</option>
                    }
                    </select>
                </div>
                <div className="inputModal">
                    <label>Ingrese un nuevo título para su motivo de contacto</label>
                    <input value={newReason} placeholder="Nombre" onChange={ (e) => {setNewReason(e.target.value)}}/>
                </div>
                <div className="inputModal">
                    <label>Respuesta estandar</label>
                    <textarea 
                        maxlength="2000" 
                        value={newDescriptionReason} 
                        placeholder="respuesta estandar | máximo 2000 letras" 
                        onChange={ (e) => {setNewDescriptionReason(e.target.value)}}
                    />
                </div>
                <div className="inputModal">    
                    <label>Seleccione el departamento al cual desea asociar</label>
                    <select onChange={ (e) => {setNewDepartment(e.target.value)}}>
                        <>
                        <option disabled={true} selected value="0">Seleccione el departamento que desea asociar</option>
                        {CurrentListDepartment && CurrentListDepartment.map( (department) => {
                            return (
                                newDepartment === department.Id ?
                                <option selected key={department.Id} value={department.Id}>{department.Name}</option>
                               :
                               <option key={department.Id} value={department.Id}>{department.Name}</option>
                            )
                        })}
                        </>
                    </select>
                </div>
                <div className="inputModal">   
                    <div className="inputModalLine">
                        <input checked={newMailCreate} className="inputCheckbox" name="Ingrese un nuevo motivo" type="checkbox" onChange={ (e) => {setNewMailCreate(!newMailCreate)}}/>
                        <label>Envia email al crear ticket</label>
                    </div>
                    <div className="inputModalLine">
                        <input checked={newMailClose} className="inputCheckbox" placeholder="Ingrese un nuevo motivo" type="checkbox" onChange={ (e) => {setNewMailClose(!newMailClose)}}/>
                        <label>Envia email al cerrar ticket</label>
                    </div>
                </div>
                </div>
                <ListaDeBotonesModals 
                handlerSubmit={() => {
                    editRegisterModal(
                        mutationEditReason, 
                        {
                            id : modalDataUpdate,
                            value: {Name: newReason, MatterId : newSubject, SendMailOnCreate: newMailCreate, SendMailOnClose: newMailClose, DefaultResponse: newDescriptionReason, TicketDepartmentId: newDepartment}
                        })
                    setNewType("");
                    }} 
                    handleCancel={() => {
                        setIsModalOpenedUpdate(false);
                        setNewType("");
                    }}/>
            </CustomModal>

            {/* //////////////////////////////FIN MODALES////////////////////////// */}
            <form className="containerForm" onSubmit={handleSubmit}>
                {/* <div className={css.titleForm}>
                    <h1>
                        MOTIVO DE CONTACTO
                    </h1>
                </div> */}
                <div className="card card--white">
                    <div className="headerForm">
                        <InputForm type="input" label="ABM Subject" idList="sbuject" setState={setSearchReason} searchTypes={search}
                         listOptions={Reason} listOptionsInitial={ReasonInitial} setCurrentListType={setNewReason}
                         setPagination={setPagination}/>
                        <ListaDeBotones
                            handlerSubmit={() => handleSubmit()}
                            handlerSubmitDelete={() => deleteType()}
                        />
                    </div>
                </div>
                <div className="card card--white">
                <ResultSearch 
                    arrayToIterate={Reason}
                    editRegister={editRegister}
                    deleteRegister={deleteType}
                    showColumnsName={["Name", "TypeName", "MatterName"]}
                    columnNameTranslate={{
                        Name: "Motivo de contacto",
                        TypeName: "Tipo de contacto",
                        MatterName: "Asunto de contacto",
                    }}
                />
                {
                    ((Reason.length > 0) || (Reason.length == 0 && searchReason.length == 0)) &&
                    <div className="contFooter">
                        <Pagination totalRow={totalRowPage} pagination={pagination} setPagination={setPagination}/>
                    </div>
                }
                </div>
            </form>
        </>
    );
}