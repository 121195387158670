import React from "react";
import css from "./styles.module.scss";
import { DefaultButton } from "../../share/buttons";

import beforeNavigate from "../../../assets/icons/beforeNavigate.svg"
import nextNavigate from "../../../assets/icons/nextNavigate.svg"

export default function Pagination({ pagination, setPagination, totalRow }) {
  
  return (
    <div className={css.containerPagination}>
      <div type="button" className={pagination === 1 ? css.disabled : undefined} onClick={() => setPagination(pagination !== 1 ? pagination-1 : pagination)}>        
        <DefaultButton
          title=""
          add={beforeNavigate}
          type="left"
          // bgColor={colors.primaryLightBlue}
          color="#676767"
        />
      </div>
      <p>{pagination}</p>
      <div className={Math.ceil(totalRow/5) <= pagination ? css.disabled : undefined} type="button" onClick={() => {
          setPagination(Math.ceil(totalRow/5) !== pagination ? pagination+1 : pagination)
        }}>
        <DefaultButton
          title=""
          add={nextNavigate}
          // bgColor={colors.primaryLightBlue}
          color="#676767"
        />
      </div>
    </div>
  );
}
