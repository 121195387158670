import React from "react";
import css from "./styles.module.scss"
import searchIcon from "../../../assets/icons/searchIcon.svg"

export default function InputForm({
  type="input",
  label="Default",
  idInput="default",
  listOptions,
  setState,
  searchTypes,
  setPagination
}) {

  function handleChange(e){
    const { target } = e;
    const { value } = target;
    setState(value);
    setPagination(1);
  }

  if(type==='input'){
    return (
      <div 
        onClick={() => { searchTypes() }}
        className={css.containerInput}
      >
        {/* <label htmlFor={idInput}>{label}</label> */}
        {/* <div className="containerImg">
            <span className="material-icons" src={searchIcon}>Buscar</span>
          </div> */}
        <img alt="searchIcon" src={searchIcon}/>
        <input name="matter" id="matter" placeholder="Buscar" onChange={handleChange}/>
      </div>
    );
  }
  if(type==='dataList'){
    return (
      <div className={css.containerInput}>
        <label htmlFor={idInput}>{label}</label>
        <input list="lists" name={idInput} id={idInput} onChange={handleChange}/>
        <datalist id="lists">
            {listOptions.map( (option) => 
                <option key={option.Id} value={option.Name} />
            )}
        </datalist>
      </div>
    );
  }
  if(type==='select'){
    return (
      <div className={css.containerInput}>
        <label htmlFor={idInput}>{label}</label>
        <select onChange={handleChange}>
            {listOptions.map( (option) => 
                <option key={option.Id} value={option.Name}>{option.Name}</option>
            )}
        </select>
      </div>
    );
  }
  return <h2>nosndosd</h2>
}
