import { ENDPOINTS } from "./constants/endpoints"
import http from "./utils/http";

export function getUserData(url) {
    if(window.location.href.indexOf("localhost") >= 0)
    {
        return http.get(
            `${url}/${ENDPOINTS.USER_DATA}`, { withCredentials: true });
    }
    else
    {
        return http.get(`http://bknord.integracion.ambientedetesting.com.ar:9080/${ENDPOINTS.USER_DATA}`, { withCredentials: true });
    }
}
// -----------------------------------------------------ENDPOINT TYPES-------------------------------------------------
export function getTypes(value, token, URL) {
return http.get(
    (value.keySearch ? `${URL}/${ENDPOINTS.TYPES}?page=${value.pagination-1}&pagesize=5&name=${value.keySearch}`
    : `${URL}/${ENDPOINTS.TYPES}?page=${value.pagination-1}&pagesize=5`), {useAuth : token})
    
}

export function getTypesAll(token, URL) {
return http.get(
    `${URL}/${ENDPOINTS.TYPES}/all`, {useAuth : token});
}

export function TypeAlta(values, token, URL) {
return http.post(
    `${URL}/${ENDPOINTS.TYPES}`, 
    values, 
    {useAuth : token}
    );
}
export function TypeEdit(values, token, URL) {
    return http.patch(
        `${URL}/${ENDPOINTS.TYPES}/${values.id}`, 
        values.value, 
        {useAuth : token}
    );
}

export function typeDelete(values, token, URL) {
    return http.delete(
        `${URL}/${ENDPOINTS.TYPES}/delete?id=${values}`, {useAuth : token}
    );
}

// -----------------------------------------------------ENDPOINT Subject-------------------------------------------------

export function getSubject(value, token, URL) {
return http.get(
    (value.keySearch !== '' ? `${URL}/${ENDPOINTS.SUBJECT}?page=${value.pagination-1}&pagesize=5&name=${value.keySearch}`
    : `${URL}/${ENDPOINTS.SUBJECT}?page=${value.pagination-1}&pagesize=5`), {useAuth : token})
}

export function getSubjectAll(token, URL, filter) {
return http.get(
    !filter ? `${URL}/${ENDPOINTS.SUBJECT}/all` : `${URL}/${ENDPOINTS.SUBJECT}/all?typeId=${filter}`, {useAuth : token}
    );
}

export function getDepartmentAll(token, URL) {
return http.get(`${URL}/${ENDPOINTS.DEPARTMENT}`, {useAuth : token});
}

export function altaSubject(values, token, URL) {
return http.post(
    `${URL}/${ENDPOINTS.SUBJECT}`,
    values, 
    {useAuth : token}
    );
}

export function subjectEdit(values, token, URL) {
    return http.patch(
        `${URL}/${ENDPOINTS.SUBJECT}/${values.id}`, 
        values.value, 
        {useAuth : token}
    );
}

export function subjectDelete(values, token, URL) {
return http.delete(
    `${URL}/${ENDPOINTS.SUBJECT}/delete?id=${values}`, {useAuth : token}
    );
}

// -----------------------------------------------------ENDPOINT Reasons-------------------------------------------------

export function getReasonAll(token, URL) {
    return http.get(
        `${URL}/${ENDPOINTS.REASON}/all`, {useAuth : token}
        );
    }

export function getReason(value, token, URL) {
    return http.get(
    (value.keySearch ? `${URL}/${ENDPOINTS.REASON}?page=${value.pagination-1}&pagesize=5&name=${value.keySearch}`
        : `${URL}/${ENDPOINTS.REASON}?page=${value.pagination-1}&pagesize=5`), {useAuth : token});
    }
    
    export function addReason(values, token, URL) {
    return http.post(
        `${URL}/${ENDPOINTS.REASON}`,
        values, 
        {useAuth : token}
        );
    }

    export function ReasonEdit(values, token, URL) {
        return http.patch(
            `${URL}/${ENDPOINTS.REASON}/${values.id}`, 
            values.value, 
            {useAuth : token}
        );
    }
    
    export function deleteReason(values, token, URL) {
    return http.delete(
        `${URL}/${ENDPOINTS.REASON}/delete?id=${values}`, {useAuth : token}
        );
    }