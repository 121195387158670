import React from "react";
import { DefaultButton } from "../buttons";
import "./botones.scoped.scss";

export function ListaDeBotones({
  handlerSubmit = "",
}) {
  return (
      <div
        onClick={() => handlerSubmit()}
        role="button"
        tabIndex={0}
        className="buttonCont"
      >
        <DefaultButton
          title="Nuevo"
          color="white"
          borderRadius="3px"
        />
      </div>
  );
}

export function ListaDeBotonesModals({
  handlerSubmit = "",
  handleCancel = "",
}) {
  return (
    <div className="containerButtons">
      <div className="buttons">
        <div
          onClick={() => handleCancel()}
          role="button"
          tabIndex={0}
        >
          <button className="button">Cancelar</button>
        </div>
      </div>
      <div className="buttons">
        <div
          onClick={() => handlerSubmit()}
          role="button"
          tabIndex={0}
        >
          <button style={{color: "#2C73B4"}} className="button">Aceptar</button>
        </div>
      </div>
    </div>
  );
}
